// contexts/CategoryContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useCart } from "./CartContext";
import { useRouter } from "next/router";
import { megaMenuItems } from "~/constants/mega-menu";

const ProductContext = createContext();

export const ProductContextProvider = ({ children }) => {
  const [productData, setProductData] = useState(null);
  const { abortController } = useCart();
  const router = useRouter();
  const { query } = router;
  const { categoryHandle } = query;

  const fetchData = async () => {
    let productId;
    if (typeof window !== "undefined") {
      productId = localStorage?.getItem("product_id");
      //console.log("dffgdfdfdfd" + JSON.stringify(productId));
    }

    try {
      const variantResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-product-variants`,
        {
          method: "POST",
          body: JSON.stringify({ productId: productId }),
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
        }
      );

      const data = await variantResponse.json();
      //const data = await Promise.all(catData);

      setProductData(data);
    } catch (error) {
      console.log("get category Error", error);
    }
    return null;
  };

  useEffect(() => {
    if (productData === null) {
      fetchData();
    }
  }, [productData]);

  // useEffect(() => {
  //   const intervalId = setInterval(fetchData, 600000); // Fetch data every minute

  //   return () => clearInterval(intervalId);
  // }, []);


  return (
    <ProductContext.Provider value={productData}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};
