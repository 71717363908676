export const DesktopLinks = () => {
  return [
    // {
    //   id: 1,
    //   path: '/shop',
    //   text: 'Shop'
    // },
    {
      id: 2,
      path: '/our-difference',
      text: 'Our Difference'
    },
    {
      id: 3,
      path: '/our-story',
      text: 'Our Story'
    },
    {
      id: 4,
      path: '/our-team',
      text: 'Our Team'
    },
    {
      id: 5,
      path: '/nutrition',
      text: 'Nutrition'
    },
    {
      id: 6,
      path: '/faq',
      text: 'FAQ'
    },
    {
      id: 7,
      path: '/consultation',
      text: 'Consultation'
    }
  ]
}
