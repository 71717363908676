

const FullScreenLoader = ()=>{


    return (
        <div className="loading">Loading&#8230;</div>
    )
}


export default FullScreenLoader