import React, { useMemo } from "react";

import type { ApolloError } from "@apollo/client";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

import { GUEST_SESSION_USER_ID, TUCKERTUB_AUTH_TOKEN, WOOCOMMERCE_TUCKERTUB_LOCALSTORAGE_CUSTOMER_INFO, WOOCOMMERCE_TUCKERTUB_LOCALSTORAGE_ORDER, WOOCOMMERCE_TUCKERTUB_LOCALSTORAGE_ORDER_EMAIL, WOOCOMMERCE_TUCKERTUB_ORDER } from "~/constants";
import type { GetUserQuery } from "~/graphql/__generated__";
import { useGetUserQuery } from "~/graphql/__generated__";

interface AuthResponse {
  isAuthenticated: boolean;
  user: GetUserQuery["user"];
  loading: boolean;
  called: boolean;
  error: ApolloError | undefined;
  logout?: () => void;
}

const UnauthorizeResponse: AuthResponse = {
  isAuthenticated: false,
  user: null,
  loading: false,
  called: true,
  error: {
    message: "unauthorized",
    graphQLErrors: [],
    clientErrors: [],
    networkError: {
      statusCode: 401,
      message: "unauthorized",
      bodyText: "unauthorized",
      name: "unauthorized",
    },
    extraInfo: "",
    name: "unauthorized",
    cause: "unauthorized",
    stack: "unauthorized",
  },
};

export const useAuthV2 = (): AuthResponse => {
  const CookieToken = Cookies.get(TUCKERTUB_AUTH_TOKEN);
  const decodeToken = CookieToken && jwtDecode(CookieToken || "");
  const userId = decodeToken ? (decodeToken as any).data.user.id : "";
  const { data, called, loading, error } = useGetUserQuery({
    variables: {
      id: userId,
    },
  });
  if (!CookieToken) return UnauthorizeResponse;

  const logout = () => {
    Cookies.remove(TUCKERTUB_AUTH_TOKEN);
    if (typeof window !== "undefined") {
      localStorage.removeItem(GUEST_SESSION_USER_ID);
    }
    localStorage.removeItem("woo-session")
    localStorage.removeItem("woo-next-cart")
    localStorage.removeItem(WOOCOMMERCE_TUCKERTUB_LOCALSTORAGE_CUSTOMER_INFO)
    localStorage.removeItem(WOOCOMMERCE_TUCKERTUB_ORDER)
    localStorage.removeItem(WOOCOMMERCE_TUCKERTUB_LOCALSTORAGE_ORDER)
    localStorage.removeItem(WOOCOMMERCE_TUCKERTUB_LOCALSTORAGE_ORDER_EMAIL)
    window.location.replace("/");
  };

  // const userId = (decodeToken as any).data.user.id
  // if (!CookieToken) return UnauthorizeResponse
  // console.log('decodeToken', userId)
  return {
    isAuthenticated: called && !loading ? !!data?.user : true,
    user: data?.user,
    loading,
    error,
    called,
    logout,
  };
};
