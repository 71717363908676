import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import { GetServerSidePropsContext } from 'next';

import { TUCKERTUB_AUTH_TOKEN } from '~/constants';
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes('invalid-secret-key')) {
        Cookies.remove(TUCKERTUB_AUTH_TOKEN);
        window.location.replace('/account');
      }
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink((operation, forward) => {
  /**
   * If session data exist in local storage, set value as session header.
   */
  const session = process.browser ? localStorage.getItem('woo-session') : null;

  if (session) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        'woocommerce-session': `Session ${session}`
      }
    }));
  }
  const token = Cookies.get(TUCKERTUB_AUTH_TOKEN);
  //console.log('user token ============================== here', token);
  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        Authorization: `Bearer ${token}`
      }
    }));
  }
  return forward(operation);
});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    if (!process.browser) {
      return response;
    }

    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;
    const session = headers.get('woocommerce-session');

    if (session) {
      // Remove session data if session destroyed.
      if (session === 'false') {
        localStorage.removeItem('woo-session');

        // Update session new data if changed.
      } else if (localStorage.getItem('woo-session') !== session) {
        localStorage.setItem('woo-session', headers.get('woocommerce-session'));
      }
    }

    return response;
  })
);

// Apollo GraphQL client.
const client = new ApolloClient({
  link: errorLink.concat(
    middleware.concat(
      afterware.concat(
        createHttpLink({
          uri: `${process.env.WOOCOMMERCE_ENDPOINT}/graphql`,
          fetch
        })
      )
    )
  ),
  cache: new InMemoryCache()
});

export default client;
