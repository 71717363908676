import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'

export const SocialIcons = [
  {
    id: 1,
    icon: <FaInstagram />,
    url: 'https://www.instagram.com/tuckertubpetfood'
  },
  {
    id: 2,
    icon: <FaFacebookSquare />,
    url: 'https://www.facebook.com/tuckertubpetfood'
  }
]
