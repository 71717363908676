import { useRouter } from "next/router"; // Import useRouter for redirection

import {
  TUCKERTUB_AUTH_TOKEN,
  TUCKERTUB_COUPON_REFERRED,
  WOOCOMMERCE_TUCKERTUB_ORDER,
} from "~/constants";
import type { ICart } from "~/context/CartContext";
import type { LineItem } from "~/types";
import { checkCompatibleShippingZone } from "~/utilities";
import type { CheckoutFormShippingInput } from "~/validation";
import Cookies from "js-cookie";
import { wooCommerceAPI } from "~/lib/WooCommerce";
import toast from "react-hot-toast";

interface CreateWooOrderProps {
  cartTotal?: number;
  isCreateOrder: boolean;
  cart: ICart;
  shippingInformation: any;
  abortController: AbortController | null;
  user: any;
  orderEmail: string;
  setOrder: (payload: any) => void;
  shippingOptions: any;
  shipping_lines: any;
  coupon_lines: {
    code: string;
  }[];
}

interface getCompatibleShippingZoneProps {
  values: CheckoutFormShippingInput;
  cart: ICart;
  shippingData: any;
  setCheckoutView: (payload: {
    isEmailView: boolean;
    isOrderAddressView: boolean;
    isDeliveryView: boolean;
    isPayView: boolean;
    isConfirmedThankYouView: boolean;
    isIncompatibleShippingView: boolean;
  }) => void;
}

export const getCompatibleShippingZone = async (
  orderData: getCompatibleShippingZoneProps
) => {
  const { values, cart, shippingData, setCheckoutView } = orderData;

  const compatibleShippingZone = checkCompatibleShippingZone(
    values,
    cart,
    shippingData
  );

  if (!compatibleShippingZone) {
    return setCheckoutView({
      isEmailView: false,
      isOrderAddressView: false,
      isDeliveryView: false,
      isPayView: false,
      isConfirmedThankYouView: false,
      isIncompatibleShippingView: true,
    });
  } else {
    return compatibleShippingZone;
  }
};

export const createWooOrder = async (orderData: CreateWooOrderProps) => {
  const {
    cartTotal,
    isCreateOrder,
    cart,
    shippingInformation,
    abortController,
    user,
    orderEmail,
    setOrder,
    router,
    coupon_lines = [],
    shippingOptions,
    shipping_lines = [],
  } = orderData;

  try {
    const foundOrder = localStorage.getItem(WOOCOMMERCE_TUCKERTUB_ORDER);
    const parsedOrder = JSON.parse(foundOrder ?? "{}");

    /* determine if we have free or standard shipping */

    const minifiedLineItems: LineItem[] = cart.lineItems.map((lineItem) => ({
      product_id: lineItem.id,
      quantity: lineItem.quantity,
    }));

    const formattedOrderData = {
      ...shippingInformation,
      customerId: user?.databaseId ?? 0,
      line_items: minifiedLineItems,
      cart,
      coupon_lines,
      shipping_lines,
      customer_note: shippingInformation?.shipping?.note,
      // customer_note: Cookies.get(TUCKERTUB_COUPON_REFERRED)
      //   ? `Refferred by: ${Cookies.get(TUCKERTUB_COUPON_REFERRED)}`
      //   : "",
    };

    if (isCreateOrder) {
      const accessToken = Cookies.get(TUCKERTUB_AUTH_TOKEN);
      const isGuestCheckout = !accessToken;

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/orders/create-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
          body: JSON.stringify({
            orderInput: formattedOrderData,
            orderEmail: user?.email ?? orderEmail,
            isGuestCheckout,
            accessToken: accessToken || "",
          }),
        }
      );

      if (response.status === 401) {
        Cookies.remove(TUCKERTUB_AUTH_TOKEN);
        window.location.replace("/account");
      }

      if (!response.ok) {
        const text = await response.json();
        console.log("errrrr text", text);
        if (text && text.error === "Coupon usage limit has been reached.") {
          // Handle coupon limit reached error
          //alert("Coupon usage limit has been reached.");
          //toast.error(text.error)
          // localStorage.setItem("couponCodeError", text.error)
          toast.error(text.error, {
            duration: 3000, // 3 seconds
            position: 'top-right' // Position of the toast notification,
          });

          //const router = useRouter();
          //router.push("/checkout"); // Redirect to checkout page
          // window.location.replace("/checkout");
          throw new Error(text.error);
          return;
        } else {
          toast.error("Error creating order", {
            duration: 3000, // 3 seconds
            position: 'top-right' // Position of the toast notification,
          });
          // Throw generic error if not coupon limit reached
          throw new Error(text.error || "Error creating order");
        }
      }

      const data = await response.json();

      console.log("OrderCreatedData=========", data);
      localStorage.setItem("id", data.id);

      const updatedOrder = {
        id: data.id,
        shipping: {
          ...data.shipping,
        },
        billing: {
          ...data.billing,
        },
        line_items: data.line_items,
        shipping_lines: data.shipping_lines,
        shippingOptions: shippingOptions,
        total: data.total,
        email: user?.email ?? orderEmail,
      };

      console.log("ADDRESSVIEWSHIPPINGOPTIONS", updatedOrder);

      setOrder(updatedOrder);

      localStorage.setItem(
        WOOCOMMERCE_TUCKERTUB_ORDER,
        JSON.stringify(updatedOrder)
      );

      return data;
    } else {
      const updatedOrder: any = {
        shipping: {
          ...shippingInformation.shipping,
        },
        billing: {
          ...shippingInformation.billing,
        },
        line_items: formattedOrderData.line_items,
        shipping_lines: [],
        shippingOptions: shippingOptions,
        total: `${cartTotal}`,
        email: user?.email ?? orderEmail,
      };

      if (parsedOrder?.id) {
        const retrievedWooCommerceOrderResponse = await fetch(
          `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/orders/get-order`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orderId: parsedOrder.id,
            }),
          }
        );

        const retrievedWooCommerceOrderData =
          await retrievedWooCommerceOrderResponse.json();
        console.log(
          "retrievedWooCommerceOrderData53",
          retrievedWooCommerceOrderData
        );
        if (
          retrievedWooCommerceOrderData?.status === "pending" ||
          retrievedWooCommerceOrderData?.status === "created"
        ) {
          const response = await fetch(
            `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/orders/delete-order`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              signal: abortController?.signal,
              body: JSON.stringify({
                orderId: parsedOrder.id,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Error updating order");
          }

          const data = await response.json();

          console.log("DELETE ORDER", data);
        }
      }

      setOrder(updatedOrder);

      localStorage.setItem(
        WOOCOMMERCE_TUCKERTUB_ORDER,
        JSON.stringify(updatedOrder)
      );

      return updatedOrder;
    }
  } catch (error) {
    throw error;
  }
};
